<!--
 * @Description: 系统管理 日志管理 操作日志 systemHandleLogManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="queryButtonForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label="操作人"
                          label-width="90px">
              <el-input v-model="queryButtonForm.logPerson"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="操作类型"
                          label-width="90px">
              <el-select v-model="queryButtonForm.logOperateType"
                         placeholder="请选择">
                <el-option v-for="item in typeList"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="操作时间"
                          label-width="90px">
              <el-date-picker v-model="dateTimes"
                              type="daterange"
                              value-format="yyyyMMddHHmmss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              align="right"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="queryButton">查 询</el-button>
          <el-button type="info"
                     @click="resetForn"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>

    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>操作日志</span>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="logs"
                  ref="tableRef"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  @getList="getList"
                  :paginationConfig='paginationConfig'>
          <template slot="isFirst">
            <el-table-column label="序号"
                             width="100">
              <template slot-scope="scope">
                {{scope.$index+1 +(pageNum-1)*pageSize}}
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      // 组件时间选择器快捷选项
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      //分页样式
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0, 'current-page': 1 },
      //时间选择器
      dateTimes: [],
      //下拉框内容
      typeList: [
        // {
        //   label: '全部',
        //   value: '',
        // },
        {
          label: '新增',
          value: 'insert',
        },
        {
          label: '修改',
          value: 'update',
        },
        {
          label: '删除',
          value: 'delete',
        },
      ],
      //表格边框
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      //表格的表头
      tableColumnList: [
        {
          prop: 'logType',
          label: '日志类型',
        },
        {
          prop: 'logLevel',
          label: '日志等级',
        },
        {
          prop: 'logIp',
          label: 'IP地址',
        },
        {
          prop: 'logContent',
          label: '日志详情',
        },
        {
          prop: 'logPerson',
          label: '操作人',
        },
        {
          prop: 'logTime',
          label: '操作时间',
        },
      ],
      //表内容
      logs: { list: [] },
      //搜索页
      queryButtonForm: {
        logPerson: '', //操作人名
        endTime: '', //结束时间
        startTime: '', //开始时间
        logOperateType: '', //操作类型
      },
      pageNum: 1,
      pageSize: 15,
      //日期快速选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      Pager: null,//获取分页器
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    //获取分页器组件
    this.Pager = this.$refs.tableRef.$children[1]
    this.queryTableList()
  },
  //方法集合
  methods: {
    //初始化页面
    queryTableList () {
      this.queryButtonForm['pageNum'] = this.listMap.pageNum;
      this.queryButtonForm['pageSize'] = this.pageSize;
      this.$systemHandleLogManagement.queryListByPage(this.queryButtonForm).then((response) => {
        let list = response.resultEntity.list
        this.logs.list = list
        this.paginationConfig.total = response.resultEntity.total
        this.listMap.pageNum = response.resultEntity.pageNum;
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //搜索框
    queryButton () {
      if (this.dateTimes.length == 2) {
        this.queryButtonForm.startTime = this.dateTimes[0]
        this.queryButtonForm.endTime = this.dateTimes[1]
      }
      this.Pager.internalCurrentPage = 1
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.queryTableList()
    },
    // 重置
    resetForn () {
      this.queryButtonForm = {}
      this.dateTimes = []
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.Pager.internalCurrentPage = 1
      this.queryTableList()
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
//@import url(); 引入公共css类
// 最外层div
.mainbody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // min-height: calc(100% - #{$topSelectHeight} - 16px);
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      // margin-bottom: 10px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>
